import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import LoadingIndicator from './LoadingIndicator';
import BookingItemModal from './modal/BookingItemModal';
import UnitType from '../constants/UnitType';
import ApprovalStatus from '../constants/ApprovalStatus';
import UserRole from '../constants/UserRole';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import koLocale from '@fullcalendar/core/locales/ko';
import { UserContext } from '../contexts/UserContext';
import * as api from '../apis';
import * as utils from '../utils';

const BookingCalendar = ({ standalone, unitType, calendarUnitId = null, bookingStatus = false }) => {
	const { user } = useContext(UserContext);

	const [isLoading, setIsLoading] = useState(false);
	const [selectedDate, setSelectedDate] = useState({
		from: utils.getFirstDayOfMonth(new Date()),
		to: utils.getEndDayOfMonth(new Date()),
	});
	const [bookingList, setBookingList] = useState([]);
	const [bookingStatusList, setBookingStatusList] = useState({ booking_disable: [], booking_fulled: [] });
	const [bookingItemModalData, setBookingItemModalData] = useState(null);
	const [bookingItemModalVisibility, setBookingItemModalVisibility] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		if (bookingStatus) {
			Promise.all([getBookings(), getBookingStatus()])
				.then((values) => {
					setBookingList(values[0]);
					setBookingStatusList(values[1]);
				})
				.catch((err) => console.error(err))
				.finally(() => setIsLoading(false));
			return;
		}

		getBookings()
			.then((res) => {
				setBookingList(res);
			})
			.catch((err) => console.error(err))
			.finally(() => setIsLoading(false));
	}, [calendarUnitId, selectedDate, unitType]);

	// 예약 정보 조회
	const getBookings = () => {
		const from = utils.convertDateToStr(selectedDate.from);
		const to = utils.convertDateToStr(selectedDate.to);

		let filterParams = [
			`unit_type=${unitType}`,
			`approval_statuses[]=${ApprovalStatus.APPROVED}`,
			`approval_statuses[]=${ApprovalStatus.PENDING}`,
			`page_size=${Number.MAX_SAFE_INTEGER}`,
			`from=${from}`,
			`to=${to}`,
		];

		// 운영자가 아니고 실시간 예약 현황 모달 화면이 아닐 경우, 사용자 본인의 예약 현황을 조회한다.
		if (!UserRole.isAdminRole(user?.user_role) && !bookingStatus) {
			return new Promise((resolve, reject) => {
				api.getMyBooking(filterParams)
					.then((res) => {
						if (res && res.data) {
							resolve(res.data);
						}
					})
					.catch((err) => {
						console.error(err);
						reject();
					});
			});
		} else {
			if (calendarUnitId) {
				filterParams.push(`unit_id=${calendarUnitId}`);
			}

			return new Promise((resolve, reject) => {
				api.getBookings(filterParams)
					.then((res) => {
						if (res && res.data) {
							resolve(res.data);
						}
					})
					.catch((err) => {
						console.error(err);
						reject();
					});
			});
		}
	};

	// 예약 현황 조회
	const getBookingStatus = () => {
		const from = utils.convertDateToStr(selectedDate.from);
		const to = utils.convertDateToStr(selectedDate.to);

		let filterParams = [`from=${from}`, `to=${to}`];

		return new Promise((resolve, reject) => {
			api.getBookingStatus(calendarUnitId, filterParams)
				.then((res) => {
					if (res && res.data) {
						resolve(res.data);
					}
				})
				.catch((err) => {
					console.error(err);
					reject();
				});
		});
	};

	const onChangeDatesSet = (dateInfo) => {
		const calendarViewType = dateInfo.view.type;

		// 주간 캘린더, 목록 캘린더일 경우
		if (calendarViewType === 'timeGridWeek' || calendarViewType === 'listWeek') {
			const currentStart = dateInfo.start;
			const currentEnd = new Date(dateInfo.end);

			// dateInfo.end가 주간 캘린더의 마지막 날보다 +1일 되어있어서 -1일 처리
			currentEnd.setDate(currentEnd.getDate() - 1);

			// 한 주에 두 달이 걸쳐있는 경우 처리
			if (currentStart.getMonth() !== currentEnd.getMonth()) {
				const firstDayOfMonth = utils.getFirstDayOfMonth(currentStart);
				const lastDayOfMonth = utils.getEndDayOfMonth(currentEnd);

				setSelectedDate({ from: firstDayOfMonth, to: lastDayOfMonth });
			} else {
				const firstDayOfMonth = utils.getFirstDayOfMonth(currentStart);
				const lastDayOfMonth = utils.getEndDayOfMonth(currentStart);

				// 새로 선택된 달과 기존 선택된 달이 다른지 확인
				if (currentStart.getMonth() !== selectedDate.from.getMonth()) {
					setSelectedDate({ from: firstDayOfMonth, to: lastDayOfMonth });
				}
			}
		} else {
			const currentDate = dateInfo.view.getCurrentData().currentDate;
			const firstDayOfMonth = utils.getFirstDayOfMonth(currentDate);
			const lastDayOfMonth = utils.getEndDayOfMonth(currentDate);

			// 새로 선택된 달과 기존 선택된 달이 다른지 확인
			if (currentDate.getMonth() !== selectedDate.from.getMonth()) {
				setSelectedDate({ from: firstDayOfMonth, to: lastDayOfMonth });
			}
		}
	};

	const getBookingEvents = () => {
		// 예약 정보 이벤트
		const bookingEvents =
			bookingList.items?.map((booking) => {
				const bookingUnitType = booking.unit.unit_type_name;
				// 장비예약인 경우 시간 구분이 필요없으므로 allDay=true로 처리한다.
				// allDay를 전체 true로 하는 경우 end 시간은 포함되지 않으므로 end time에 1분을 추가하여 다음날 00시로 end 시간을 정한다.
				const allDay = bookingUnitType === UnitType.PRODUCT;
				const bookingEndDate = new Date(booking.booking_end_date);
				if (allDay) {
					bookingEndDate.setMinutes(bookingEndDate.getMinutes() + 1);
				}

				let title = '';
				switch (bookingUnitType) {
					case UnitType.ROOM:
						title = `${booking.unit.name} / ${booking.room_item?.name}`;
						break;
					default:
						title = `${booking.unit.name}`;
						break;
				}

				return {
					url: booking.id,
					title,
					allDay: allDay,
					start: booking.booking_start_date,
					end: bookingEndDate,
					classNames: [`${unitType}_status_${booking.approval_status}`],
					userName: booking.user.name,
					approvalStatus: booking.approval_status,
				};
			}) ?? [];

		// 예약 불가 이벤트
		const bookingDisableEvents =
			bookingStatusList.booking_disable?.map((disableDate) => {
				return {
					id: 'booking_disable',
					title: '예약 불가',
					allDay: true,
					start: disableDate,
					end: disableDate,
					classNames: [`booking-disable`],
				};
			}) ?? [];

		// 예약 마감 이벤트
		const bookingFulledEvents =
			bookingStatusList.booking_fulled?.map((fulledDate) => {
				return {
					id: 'booking_fulled',
					title: '예약 마감',
					allDay: true,
					start: fulledDate,
					end: fulledDate,
					classNames: [`booking-fulled`],
				};
			}) ?? [];

		// 예약 정보 이벤트에 예약 불가, 예약 마감 이벤트와 동일한 날짜가 존재하는 경우에는 해당 날짜의 예약 정보 이벤트를 제외한다.
		const filteredBookingEvents = bookingEvents.filter((bookingEvent) => {
			const bookingStart = utils.convertDateToStr(new Date(bookingEvent.start));

			return !(
				bookingDisableEvents.some((disableEvent) => bookingStart === disableEvent.start) ||
				bookingFulledEvents.some((fulledEvent) => bookingStart === fulledEvent.start)
			);
		});

		// 예약 불가와 예약 마감 이벤트에 동일한 날짜가 존재하는 경우에는 예약 불가를 우선순위 있게 처리하기 위해 예약 마감 이벤트에서 예약 불가 이벤트와 동일한 날짜는 제외한다.
		const filteredBookingFulledEvents = bookingFulledEvents.filter((fulledEvent) => {
			return !bookingDisableEvents.some((disableEvent) => fulledEvent.start === disableEvent.start);
		});

		return [...filteredBookingEvents, ...bookingDisableEvents, ...filteredBookingFulledEvents];
	};

	const onClickEvent = (eventInfo) => {
		eventInfo.jsEvent.preventDefault();

		// 실시간 예약 현황 모달일 경우 클릭 이벤트를 진행하지 않는다.
		if (bookingStatus) {
			return;
		}

		let booking = bookingList.items.find((booking) => booking.id == eventInfo.event.url);
		setBookingItemModalData(booking);
		setBookingItemModalVisibility(true);
	};

	const onSaveBookingItemModal = (booking) => {
		setBookingList({
			...bookingList,
			items: bookingList.items.map((item) => {
				if (item.id === booking.id) {
					return booking;
				}
				return item;
			}),
		});
		setBookingItemModalData(booking);
	};

	const onCloseBookingItemModal = (isEdit) => {
		setBookingItemModalVisibility(false);
		if (isEdit) {
			getBookings()
				.then((res) => {
					setBookingList(res);
				})
				.catch((err) => console.error(err));
		}
	};

	const renderEventContent = (eventInfo) => {
		const calendarViewType = eventInfo.view.type;

		switch (calendarViewType) {
			case 'dayGridMonth':
				return <>{renderDayGridMonthEventContent(eventInfo)}</>;
			case 'timeGridWeek':
			case 'timeGridDay':
				return <>{renderTimeGridEventContent(eventInfo)}</>;
			case 'listWeek':
				return <>{renderListWeekEventContent(eventInfo)}</>;
		}
	};

	const renderDayGridMonthEventContent = (eventInfo) => {
		// 예약 불가, 예약 마감 이벤트일 경우 지정한 event.title만 표시
		if (eventInfo.event.id === 'booking_disable' || eventInfo.event.id === 'booking_fulled') {
			return <BookingDisableText>{eventInfo.event.title}</BookingDisableText>;
		}

		return (
			<CalendarEventContentContainer>
				{unitType !== UnitType.PRODUCT && (
					<>
						<ApprovalStatusCircle
							status={eventInfo.event.extendedProps.approvalStatus}
						></ApprovalStatusCircle>
						<CalendarEventContentTimeText>{eventInfo.timeText}</CalendarEventContentTimeText>
					</>
				)}
				{UserRole.isAdminRole(user?.user_role) && !bookingStatus && (
					<CalendarEventContentUserName>
						{eventInfo.event.extendedProps.userName}
					</CalendarEventContentUserName>
				)}
				<CalendarEventContentTitle>{eventInfo.event.title}</CalendarEventContentTitle>
			</CalendarEventContentContainer>
		);
	};

	const renderTimeGridEventContent = (eventInfo) => {
		const eventContentText =
			UserRole.isAdminRole(user?.user_role) && !bookingStatus
				? `${eventInfo.event.extendedProps.userName} ${eventInfo.event.title}`
				: eventInfo.event.title;
		return (
			<CalendarEventContentContainer>
				<CalendarEventContentTimeText>{eventInfo.timeText}</CalendarEventContentTimeText>
				<TimeGridCalendarEventContentText>{eventContentText}</TimeGridCalendarEventContentText>
			</CalendarEventContentContainer>
		);
	};

	const renderListWeekEventContent = (eventInfo) => {
		const eventContentText =
			UserRole.isAdminRole(user?.user_role) && !bookingStatus
				? `${eventInfo.event.extendedProps.userName} ${eventInfo.event.title}`
				: eventInfo.event.title;
		return (
			<CalendarEventContentContainer>
				<ListWeekCalendarEventContentText>{eventContentText}</ListWeekCalendarEventContentText>
			</CalendarEventContentContainer>
		);
	};

	return (
		<>
			<LoadingIndicator size={50} loading={isLoading} />
			<CalendarContainer>
				<FullCalendar
					datesSet={(dateInfo) => onChangeDatesSet(dateInfo)}
					plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
					initialView="dayGridMonth"
					locale={koLocale}
					headerToolbar={{
						start: 'today prev,next',
						center: 'title',
						end: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
					}}
					buttonText={{
						today: '오늘',
						month: '월',
						week: '주',
						day: '일',
						list: '목록',
					}}
					fixedWeekCount={false}
					dayHeaderClassNames="day-header"
					events={getBookingEvents()}
					eventClick={(e) => onClickEvent(e)}
					eventContent={(e) => renderEventContent(e)}
					height={standalone ? 'calc(100vh - 220px)' : '700px'}
				/>
			</CalendarContainer>
			{bookingItemModalVisibility && (
				<BookingItemModal
					unitType={unitType}
					booking={bookingItemModalData}
					onSave={(booking) => onSaveBookingItemModal(booking)}
					onClose={(isEdit) => onCloseBookingItemModal(isEdit)}
				/>
			)}
		</>
	);
};

const CalendarContainer = styled.div`
	margin-top: 20px;
	width: 100%;

	@media only screen and (max-width: 767.98px) {
		font-size: 13px;
	}

	@media only screen and (max-width: 479.98px) {
		font-size: 10px;
	}
`;

const CalendarEventContentContainer = styled.div`
	display: flex;
	flex-wrap: wrap;

	& > *:not(:last-child) {
		margin-right: 3px;
	}
`;

const ApprovalStatusCircle = styled.span`
	align-self: center;
	display: inline-block;
	width: 8px;
	height: 8px;
	border: 4px solid;
	border-radius: 4px;

	${(props) =>
		props.status == 'approved' &&
		css`
			border-color: #3788d8;
		`}
	${(props) =>
		props.status == 'pending' &&
		css`
			border-color: #fead0a;
		`}
`;

const CalendarEventContentTimeText = styled.span`
	font-size: 13px;

	@media only screen and (max-width: 479.98px) {
		font-size: 10px;
	}
`;

const CalendarEventContentUserName = styled.span`
	white-space: break-spaces;
	font-size: 13px;
	font-weight: 700;

	@media only screen and (max-width: 479.98px) {
		font-size: 10px;
	}
`;

const CalendarEventContentTitle = styled.span`
	white-space: break-spaces;
	font-size: 13px;
	font-weight: 700;

	& > *:not(:last-child) {
		margin-right: 3px;
	}

	@media only screen and (max-width: 479.98px) {
		font-size: 10px;
	}
`;

const TimeGridCalendarEventContentText = styled.span`
	width: 100%;
	white-space: break-spaces;
	font-size: 13px;
	flex-shrink: 0;

	@media only screen and (max-width: 479.98px) {
		font-size: 10px;
	}
`;

const ListWeekCalendarEventContentText = styled.span`
	margin-top: 2px;
	width: 100%;
	white-space: break-spaces;
	font-size: 1em;
	flex-shrink: 0;
`;

const BookingDisableText = styled.span`
	margin-left: 5px;
`;

export default BookingCalendar;
